import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import BackToTop from "../../common/back-to-top.vue";
import exportLimit from "../../common/export-limit.vue";
import reportService from "./reports-service";
import utility from "@/shared/utility";
export default {
  name: "partsDueReport",
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt("userID"),
      actionList: [],
      favouriteList: [],
      specificSelected: "",
      showData: false,
      exportDialog: false,
      hideSpecific: false,
      showTable: false,
      guid:"",
      disableDate: false,
      showPart: false,
      partNum: "",
      partDesc: "",
      mainPage: "",
      excelName: "",
      page: 1,
      displayCost: "",
      totalPages: 0,
      subPage: "",
      restForm: false,
      backSlash: true,
      pageUrl: "",
      showAdd: false,
      refresh: true,
      sDate: "",
      eDate: "",
      selectedProject: "",
      selectedBusiness: "",
      selectedWarehouse: "",
      selectedPartType: "",
      selectedOem: "",
      selectedSODetails: "",
      partSearchKey: "",
      partResultList: [],
      showPartNumDetails: false,
      businessList: [],
      detailsData: [],
      warehouseList: [],
      partTypeList: [],
      uniquepartTypeList: [],
      uniqueOEM: [],
      oemList: [],
      soList: [],
      uniqueSODetails: [],
      reportData: [],
      startDate: false,
      showWarehouse: false,
      showPartType: false,
      showOem: false,
      soType: false,
      showSearch: false,
      timeoutId: null,
      endDate: false,
      debouncedGetPartList :[],
      showAllForm: false,
      maxStartDate: new Date().toISOString().split("T")[0],
      minEndDate: new Date().toISOString().split("T")[0],
      maxEndDate: new Date(new Date().getTime()).toISOString().split("T")[0],
      projectList: [],
      totalRecords: 0,
      filterObj: {
        userid: 0,
        pass: 0,
        proj_key: 0,
        part_num: "",
        bu_key: 0,
        ptype_key: 0,
        ware_key: 0,
        oem_key: 0,
        sotype_key: 0,
        PageNumber: 1,
        export: 0,
        guid:"",
      },
      headerReport: [
        { text: "SO Number", align: "start", value: "SO_Number", class: "primary customwhite--text" },
        { text: "Line No", value: "Line_no", class: "primary customwhite--text" },
        { text: "Cust PO", value: "Cust_PO", class: "primary customwhite--text" },
        { text: "RMA", value: "RMA", class: "primary customwhite--text" },
        { text: "Order No", value: "Order_No", class: "primary customwhite--text" },
        { text: "Customer Name", value: "Customer_Name", class: "primary customwhite--text" },  
        { text: "Expected Item", value: "Expected_item", class: "primary customwhite--text" },
        { text: "Expected Description", value: "Expected_description", class: "primary customwhite--text" },
        { text: "Expected Class", value: "Expected_class", class: "primary customwhite--text" },
        { text: "Expected Serial No", value: "Expected Serial_No", class: "primary customwhite--text" },
        { text: "	Order Type", value: "Type", class: "primary customwhite--text" },
        { text: "In Warehouse", value: "in_ware", class: "primary customwhite--text" },
        { text: "Reason", value: "Reason", class: "primary customwhite--text" },
      ],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.filterObj.userid = this.userId;
    this.getProjectData();
    this.debouncedGetPartList  = utility.debounce(this.getPartList, 500);
  },
  mounted() { },
  computed:{
    sortedWareOptions() {
      return utility.getAllAtTop(this.warehouseList, 'ware');
    },
    sortedPartType(){
      return utility.getAllAtTop(this.partTypeList, 'ptype');
    },
    sortedOEMList(){
      return utility.getAllAtTop(this.oemList, 'oem');
    },
    sortedSODetails(){
      return utility.getAllAtTop(this.soList, 'so_desc');
    },
   
   
  },
 
  methods: {

    //To add and remove From Fav
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //To brings page based actions to the page
    actionSetter(action) {
      if (this.actionList !== undefined && this.actionList !== null && this.actionList !== "") {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
    async exportData() {
      this.export50kData = true;
      this.exportDialog = false;
    },
    async getProjectData(){
      let projectData = await reportService.getPartsDueReportData("post", this.filterObj, false);
      this.projectList = projectData;
    },

    // to export the Execel file
    async exportExcel() {
      if (this.totalRecords <= 15000) {
        this.filterObj.export = 1;
        this.filterObj.guid=this.guid
        this.excelName =
          "Parts_Due_Report" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".xls";
        const response = await this.axios.post("/rt/parts_due_report", this.filterObj);
        let responseData = JSON.parse(response.data.body.message);
        this.export50kData = false;
        return responseData;
      } else {
        this.exportDialog = true;
        this.finishDownload();
        return true;
      }
    },
    //Start the Loader when excel process starts
    startDownload() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    // end the Loader when the process is completed
    finishDownload() {
      let LoaderDialog = {
        visible: false,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    //on Change Project
    onChangeProject() {
      this.filterObj.proj_key = this.selectedProject;
      this.filterObj.pass = 1;
      this.showAllForm = false;
      this.showGo = false;
      this.showPart = true;
      this.disableDate = true;
      this.getBusinessData();
    },
    async getBusinessData() {
      let businessData = await reportService.getPartsDueReportData("post", this.filterObj, false);
      this.businessList = businessData;
    },
    async onChangeBusiness() {
      this.hideSpecific = true;
      this.filterObj.pass = 2;
      this.filterObj.bu_key = this.selectedBusiness;
      this.detailsData = await reportService.getPartsDueReportData("post", this.filterObj, false);
      const unquieWarehouse = [...new Map(this.detailsData.map((item) => [item["ware"], item])).values()];
      this.warehouseList = unquieWarehouse.map((x) => ({
        ware: x.ware,
        ware_id: x.ware_key,
      }));
      this.warehouseList=  this.warehouseList=this.warehouseList.sort(function(a, b) { return a.ware - b.ware })
      this.showWarehouse = true;
      this.showPartType = false;
      this.showOEM = false;
      this.soType = false;
      this.displayCost = false;
      this.showSearch = false;
      this.oemList = [];
      this.partTypeList = [];
      this.oemList = [];
      this.soList = [];
      this.showCost = false;
      this.selectedWarehouse = "";
      this.selectedBusinessOem = "";
      this.selectedSODetails = "";
    },
    onChangeWarehouse(ware_id) {
      this.filterObj.ware_key = ware_id
      this.uniquepartTypeList = this.detailsData.filter((x) => x.ware_key == ware_id);
      const uniquePtype = [...new Map(this.uniquepartTypeList.map((item) => [item["ptype"], item])).values()];
      this.partTypeList = uniquePtype.map((x) => ({
        ptype: x.ptype,
        ptype_key: x.ptype_key,
      }));
      this.showPartType = true;
      this.showData = false;
      this.showOEM = false;
      this.soType = false;
      this.displayCost = false;
      this.showSearch = false;
      this.oemList = [];
      this.soList = [];
      this.showCost = false;
      this.selectedSODetails = "";
      this.selectedPartType = "";
      this.selectedOem = "";
    },
    onChangePartType(partType_id) {
      this.filterObj.ptype_key = partType_id
      this.uniqueOEM = this.uniquepartTypeList.filter((x) => x.ptype_key == partType_id);
      const uniqueClass = [...new Map(this.uniqueOEM.map((item) => [item["oem"], item])).values()];
      this.oemList = uniqueClass.map((x) => ({
        oem: x.oem,
        oem_id: x.oem_key,
      }));
      this.oemList.sort(function(a, b) { return a.oem - b.oem })
      this.showOEM = true;
      this.soType = false;
      this.displayCost = false;
      this.showSearch = false;
      this.soList = [];
      this.showCost = false;
      this.showData = false;
      this.showSearch = false;
      this.selectedSODetails = "";
      this.selectedOem = "";
    },
    onChangeOEM(oem_id) {
      this.filterObj.oem_id = oem_id
      this.uniqueSODetails = this.uniquepartTypeList.filter((x) => x.oem_key == oem_id);
      const uniqueSoDesc = [...new Map(this.uniqueSODetails.map((item) => [item["so_desc"], item])).values()];
      this.soList = uniqueSoDesc.map((x) => ({
        so_desc: x.so_desc,
        so_type_id: x.sotype_key,
      }));
      this.soType = true;
      this.displayCost = true;
      this.showSearch = false;
      this.showData = false;
      this.showCost = false;
      this.selectedSODetails = "";
      this.selectedSODetails = "";
    },

    async onClickSearch(PageNumber) {
      this.page=PageNumber;
      this.filterObj.guid=this.guid;
      (this.filterObj.pass = 3), (this.filterObj.PageNumber = PageNumber);
      (this.filterObj.bu_key = this.selectedBusiness),
        (this.filterObj.ptype_key = this.selectedPartType),
        (this.filterObj.ware_key = this.selectedWarehouse),
        (this.filterObj.oem_key = this.selectedOem),
        (this.filterObj.sotype_key = this.selectedSODetails);
      this.filterObj.part_num = this.partSearchKey;
      let tableData = await reportService.getPartsDueReportData("post", this.filterObj, false);
      this.reportData = tableData?.Resultset;
      if (this.reportData?.length > 0) {
        this.showData = true;
        // this.reportData?.forEach((element) => {
        //   element.Date_Ship = Utility.convertESTToLocal(element.Date_Ship);
        // });
      } else {
        this.reportData = [];
        let Alert = {
          type: "error",
          isShow: true,
          message: "No Record Found",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
      this.$vuetify.goTo(0);
      this.showTable = true;
      this.totalRecords = tableData?.TotalRecords;
      this.totalPages = tableData?.TotalPages;
      this.guid=tableData?.Guid
    },
    resetFunction() {
      this.guid="";
      this.selectedProject = "";
      this.disableDate = false;
      (this.partNum = ""), (this.showData = false);
      this.showGo = true;
      (this.partDesc = ""), (this.selectedBusiness = "");
      this.partSearchKey = "";
      this.restForm = false;
      this.showPart = false;
      this.showWarehouse = false;
      this.specificSelected = false;
      this.showPartType = false;
      this.showOEM = false;
      this.soType = false;
      this.displayCost = false;
      this.showAllForm = false;
      this.showSearch = false;
      this.businessList = [];
      this.projectList = [];
      this.warehouseList = [];
      this.oemList = [];
      this.partTypeList = [];
      this.oemList = [];
      this.soList = [];
      this.showTable = false;
      this.showCost = false;
      this.selectedWarehouse = "";
      this.selectedBusinessOem = "";
      this.selectedSODetails = "";
      this.hideSpecific = false;
      this.reportData = [];
      this.totalRecords = 0;
      this.timeoutId=null
      this.totalPages = 1;
      this.filterObj={
        userid:this.userId,
        pass: 0,
        proj_key: 0,
        part_num: "",
        bu_key: 0,
        ptype_key: 0,
        ware_key: 0,
        oem_key: 0,
        sotype_key: 0,
        PageNumber: 1,
        export: 0,
      }
      this.getProjectData()
    },
    onClickSpecificPartNumber() {
      this.specificSelected = true;
    },
    async searchPartNum() {
      this.partSearchKey = this.partSearchKey?.toUpperCase();
      if(this.partSearchKey?.length>2){
        this.debouncedGetPartList();
    }
    },

    async getPartList(){
      this.showPartNumDetails = true;
      let SearchObj = {
        UserId: this.userId,
        SearchStr: this.partSearchKey,
        proj_key: this.selectedProject,
      };
      
      let partResultData = await reportService.showPartNumDetailsData("post", SearchObj, false);
      this.partResultList = partResultData?.Resultset;
    },

    async showPartDesc(item) {
      this.showPartDetails = true;
      this.partNum = item.PartNo;
      this.partDesc = item.Description;
      this.partSearchKey = item.PartNo;
      this.showPartNumDetails = false;
      this.filterObj.part_num = this.partNum;
      let dataExists = await reportService.getPartsDueReportData("post", this.filterObj, false);
      if (dataExists?.message=="NA" ||!dataExists) {
        this.showAllForm = false;
      }
      else {
        this.showAllForm = true;
        
      }
    },

    clearPartShip() {
      this.showPartDetails = false;
      this.partSearchKey = "";
      this.partResultList = [];
      this.partNum = "";
      this.partDesc = "";
    },
  },
  components: {
    breadcrumbComp,
    BackToTop,
    exportLimit,
  },
};
